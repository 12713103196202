(function (angular, undefined) {
    "use strict";
    var blog = angular.module("mikesBlog");

    blog.config(["$stateProvider", "$urlRouterProvider", "markdownProvider",
        function ($stateProvider, $urlRouterProvider, markdownProvider) {
        markdownProvider.config({
            extensions: ["youtube"]
        });
        $stateProvider
            .state("blogList", {
                parent: "mike's",
                url: "/blog",
                data: {
                    pageTitle: "Mike's Blog"
                },
                views: {
                    "": {
                        templateUrl: "app/blog/templates/blogList.html",
                        controller: "mikesBlogList.ctrl",
                        controllerAs: "vm"
                    }
                }
            })
            .state("blogPost", {
                parent: "mike's",
                url: "/blog/{id:string}",
                data: {
                    pageTitle: "Mike's Blog"
                },
                views: {
                    "": {
                        templateUrl: "app/blog/templates/blogPost.html",
                        controller: "mikesBlogPost.ctrl",
                        controllerAs: "vm"
                    }
                }
            });
    }]);

})(angular);
